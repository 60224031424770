body {
  background-color: #605050;
}

.container {
  display: grid;
  background-color: #605050;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.005fr 0.05fr 1.7fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-auto-columns: 1fr; 
  grid-template-areas:
    'banner banner'
    'spanner spanner'
    'left right'
    'footer footer';
}

.banner {
  grid-area: banner;
  background-color: #38424e;
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 86px;
  color: white;
  padding-left: 10%;
}

.spanner {
  grid-area: spanner;
  background-color: #605050
}

.left {
  grid-area: left;
  background-color: #605050;
  overflow-x: auto;
  overflow-y: auto;
}

.right {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  grid-area: right;
  background-color:  white;
  margin-right: 10%;
}

.footer {
  grid-area: footer;
  background-color: #605050
}

.hs-container {
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}

.responsive-hs {
  width: 60%;
  height: auto;
  display: block;
  border: 10px solid white;
  margin: auto
}

.videos {
  padding-bottom: 5%;
}

.video-responsive {
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  box-shadow:0 0 20px rgba(0,0,0,0.8);
}


.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.review {
  font-style: italic;
}
.quote {
  padding-right: 10px;
  font-size: 24px;
}
.review-header-container {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10%;
}

.review-header {
  padding-top: 20%;
  font-size: 24px;
  font-weight: bold;
  color: white
}

.reviews {
  color: rgb(95, 93, 93);
  border: 2px solid rgb(95, 93, 93);
  margin-left: 10%;
  margin-right: 10%;
  padding: 5%;
  background-color: white;
}